(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/maintenance-context/assets/javascripts/maintenance-context.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/maintenance-context/assets/javascripts/maintenance-context.js');
"use strict";


const {
  createContext
} = React;
const MaintenanceContext = createContext();
setGlobal('svs.oddset_kambi.components.maintenance_context.MaintenanceContext', MaintenanceContext);

 })(window);